import { useEffect, useState } from 'react';
import { getSystemSettings } from '../../api/system/settings';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import getIconComponent from '../../utils/iconHandler';

const SpeedButtons = () => {
  const [buttons, setButtons] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getSystemSettings('SPEED_BUTTONS').then((res) => {
      setButtons(res);
    });
  }, []);

  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <Box className='speed-buttons' display={'flex'} gap={2}>
      {buttons &&
        buttons.map((button, index) => {
          const [path, iconName, color] = button.SettingValue.split(';'); // Felbontjuk a karakterláncot, harmadik elem a szín

          return (
            <Button
              key={index}
              variant='contained'
              sx={{
                width: 100,
                height: 100,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: color || 'primary',
              }}
              onClick={() => handleButtonClick(path)}
            >
              {getIconComponent(iconName)}
              {button.settingKey}
            </Button>
          );
        })}
    </Box>
  );
};

export default SpeedButtons;
