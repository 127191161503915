import './ActPriceList.scss';
const currencyFormatter = new Intl.NumberFormat('hu-HU', {
  style: 'currency',
  currency: 'HUF',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export default function ActPriceList(item) {
  return (
    <div>
      <p>
        {item.tantargy_descr} - {currencyFormatter.format(item.dij)}
      </p>
    </div>
  );
}
