export const TanoraTypes = [
  {
    key: 'R',
    name: 'Rendszeres',
  },
  {
    key: 'E',
    name: 'Egyedi',
  } /*
  {
    key: 'T',
    name: 'Tábor',
  },*/,
];

export const DiscountLevels = [
  {
    key: 'D',
    name: 'Diák szint',
  },
  {
    key: 'T',
    name: 'Tanóra szint',
  },
  {
    key: 'A',
    name: 'Minden szint',
  },
  {
    key: 'H',
    name: 'Rejtett - Rendszer számolja',
  },
];

export const Weekdays = [
  {
    key: 1,
    name: 'Hétfő',
  },
  {
    key: 2,
    name: 'Kedd',
  },
  {
    key: 3,
    name: 'Szerda',
  },
  {
    key: 4,
    name: 'Csütörtök',
  },
  {
    key: 5,
    name: 'Péntek',
  },
  {
    key: 6,
    name: 'Szombat',
  },
  {
    key: 7,
    name: 'Vasárnap',
  },
];

export const AttendanceTypes = [
  {
    key: 'A',
    name: 'Részt vett',
  },
  {
    key: 'C',
    name: 'Lemondta',
  },
  {
    key: 'M',
    name: 'Nem szólt/nem jött!!',
  },
];

export const DiscountValues = [
  {
    key: 0,
    name: 'Nincs ',
  },
  {
    key: 10,
    name: '10% ',
  },
  {
    key: 15,
    name: '15% ',
  },
  {
    key: 20,
    name: '20% ',
  },
  {
    key: 25,
    name: '25% ',
  },
  {
    key: 50,
    name: '50% ',
  },
  {
    key: 100,
    name: '100% ',
  },
];

export const SubjectTypes = [
  {
    key: 'Matek',
    name: 'Matematika',
  },
  {
    key: 'Infó',
    name: 'Informatika',
  },
  {
    key: 'Fizika',
    name: 'Fizika',
  },
  {
    key: 'Kémia',
    name: 'Kémia',
  },
  {
    key: 'Biológia',
    name: 'Biológia',
  },
  {
    key: 'Angol',
    name: 'Angol',
  },
  {
    key: 'Német',
    name: 'Német',
  },
  {
    key: 'Szakmai tárgy',
    name: 'Szakmai tárgy',
  },
  {
    key: 'Egyéb',
    name: 'Egyéb',
  },
  {
    key: 'Tábor',
    name: 'Tábor',
  },
];

export const SubjectDBTypes = [
  {
    key: 'MAT',
    name: 'Matematika',
  },
  {
    key: 'INF',
    name: 'Informatika',
  },
  {
    key: 'FIZ',
    name: 'Fizika',
  },
  {
    key: 'KEM',
    name: 'Kémia',
  },
  {
    key: 'BIO',
    name: 'Biológia',
  },
];
export const KifizetesJogcim = [
  {
    key: 'ADO',
    name: 'Adó',
  },
  {
    key: 'BERLET',
    name: 'Bérleti díj',
  },
  {
    key: 'ESZKOZ',
    name: 'Eszközök',
  },
  {
    key: 'REZSI',
    name: 'Rezsi kiadások',
  },
];

export const SystemSettingsAreaCodes = [
  {
    key: 'SZAMLAZZ_HU',
    name: 'Számlázz.hu interface',
  },
  {
    key: 'SPEED_BUTTONS',
    name: 'Gyorsgombok',
  },
];

export const TanoraWizzardSteps = 4;
