import React from 'react';

const Card = React.memo(({ title, children }) => (
  <div className='dx-row dx-card dx-round dx-white'>
    <h3>{title}</h3>
    {children}
  </div>
));

export default Card;
