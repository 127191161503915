import { Fragment, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { loadUser } from './actions/auth';

import './dashboard.css';
//import './css/theme.css';
import 'devextreme/dist/css/dx.material.orange.dark.compact.css';
import './css/theme-dark-orange.css';
import './css/theme-indigo.css';
import './css/timetable.css';
//Redux
import { locale } from 'devextreme/localization';
import { Provider } from 'react-redux';
import LoggedRoute from './components/hooks/LoggedRoutes';
import store from './store';
import setAuthToken from './utils/setAuthToken';

import Main from './components/layout/Main';
import Navbar from './components/layout/Navbar';
import { DataProvider } from './contexts/DataProvider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

//DevExpress.config();

const App = () => {
  locale('hu-HU');

  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    store.dispatch(loadUser());
  }, []);
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  return (
    <Provider store={store}>
      <Router future={{ v7_relativeSplatPath: true, v7_startTransition: true }}>
        <LoggedRoute>
          <DataProvider>
            <Fragment>
              <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                <Navbar />
                <Main />{' '}
              </ThemeProvider>
            </Fragment>
          </DataProvider>
        </LoggedRoute>
      </Router>
    </Provider>
  );
};

export default App;
