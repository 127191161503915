import axios from 'axios';
import { backendHOST } from '../../client-config/client-config';
import setAuthToken from '../../utils/setAuthToken';

export async function getSystemSettings(areaCode) {
  // Send request
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache', // Cache-Control fejléc beállítása
    },
  };

  try {
    const res = await axios.get(
      `${backendHOST}/api/params/SystemSettings`,
      config
    );
    if (res.status === 200) {
      const data = await res.data;

      const filteredData = data.filter((item) => item.SettingArea === areaCode);

      return filteredData;
    } else return [];
  } catch (e) {
    console.error('error', e);
  }
}
