import { createStore } from 'devextreme-aspnet-data-nojquery';
import List from 'devextreme-react/list';
import TextArea from 'devextreme-react/text-area';
import React from 'react';
import { backendHOST } from '../../../client-config/client-config';
import { TaskCloseButton } from './TaskCloseButton';
const url = backendHOST + '/api/taskManager';
 
const dsTasks = createStore({
  key: 'TaskTitle',
  loadUrl: `${url}/UserTasks`,

  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-auth-token': localStorage.token,
      'Access-Control-Allow-Origin': '*',
    };
  },
});

export class MessagePopup extends React.Component {
  constructor(props) {
    super(props);

    //   this.showInfo = () => this.props.showInfo(props.employee);

    this.MessageItem = this.MessageItem.bind(this);
  }

  const;

  render() {
    return (
      <React.Fragment>
        <h4>Feladatok</h4>

        <div className='list-container'>
          <List
            dataSource={dsTasks}
            // height={400}
            allowItemDeleting={false}
            itemRender={this.MessageItem}
            scrollByContent={true}
            scrollByThumb={true}
            useNativeScrolling={true}
          ></List>
        </div>
      </React.Fragment>
    );
  }

  MessageItem(item) {
    return (
      <div className='w3-row'>
        <div className='w3-col m3 s3'>
          <TextArea value={`(${item.id_task})${item.TaskTitle}`} />
        </div>
        <div className='w3-col m8 s8'>
          <TextArea value={item.TaskDescr} />
        </div>
        <div className='w3-col m1 s1'>
          <TaskCloseButton taskId={item.id_task} user={this.props.user} />
        </div>
      </div>
    );
  }
}
