import axios from 'axios';
import { backendHOST } from '../client-config/client-config';

export async function getDBVersion() {
  let res;
  try {
    res = await axios.get(backendHOST + '/api/admin/Version');
  } catch (e) {
    console.error(e);
  }
  if (res) {
    if (res.status === 200) {
      return {
        isOk: true,
        data: res.data,
      };
    } else {
      return {
        isOk: false,
      };
    }
  } else {
    return {
      isOk: false,
    };
  }
}
